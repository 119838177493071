import { BACKEND_DOMAIN, BRAND_DOMAIN } from '../constants/Branding';
import { getBrandDomain } from '../util/stringUtil';

export const DevUrlsMap = {
  REACT_APP_REST_API_URL: `https://stage-api.quickexch.com/api/v1`,
  REACT_APP_REST_API_V2: `https://stage-api.quickexch.com`,
  REACT_APP_REST_REPORTING_API_URL: `https://stage-reporting.quickexch.com`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://stage-reporting.quickexch.com/push-notifications`,
  BINARY_WEBSOCKET_URL: `https://stage-user-api.quickexch.com/hypex-ws`,
  REACT_APP_REST_ANUMATI_API_URL: `https://auditdev.quickexch.com`,
  REACT_APP_AGPAY_API_URL: `https://stage-agpay.quickexch.com`,
};

export const ProdUrls = {
  REACT_APP_REST_API_URL: `https://api.${BACKEND_DOMAIN}/api/v1`,
  REACT_APP_REST_API_V2: `https://api.${BACKEND_DOMAIN}`,
  REACT_APP_REST_REPORTING_API_URL: `https://reporting.${BACKEND_DOMAIN}`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://reporting.${BACKEND_DOMAIN}/push-notifications`,
  BINARY_WEBSOCKET_URL: `https://feed.mysportsfeed.io/odds-feed`,
  REACT_APP_REST_ANUMATI_API_URL: `https://anumati.${BACKEND_DOMAIN}/approvals/v1/applications`,
  REACT_APP_AGPAY_API_URL: `https://agpay.${BACKEND_DOMAIN}`,
};

export const EnvUrlsMap = {
  development: DevUrlsMap,
  stage: DevUrlsMap,
  production: ProdUrls,
};

export function getBaseUrl(env: string, url: string) {
  return EnvUrlsMap[env][url];
}
