import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonSpinner } from '@ionic/react';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import axios from 'axios';
import { useHistory, Redirect } from 'react-router-dom';
import AgeRestriction from '../Footer/RestrictionsInfo/AgeRestriction';
import TerritoriesRestriction from '../Footer/RestrictionsInfo/TerritoriesRestriction';

import './LoginForm.scss';
import {
  fetchBalance,
  loginSuccess,
  loginFailed,
  requestStart,
  requestEnd,
} from '../../store';
import { RootState } from '../../models/RootState';
import { IonLabel } from '@ionic/react';
import API from '../../api';
import ForgotPwdForm from '../ForgotPassword/ForgotPassword';
import Modal from '../Modal/Modal';
// import ReCAPTCHA from 'react-google-recaptcha';
import API_V2 from '../../svls-api';

type StoreProps = {
  loginSuccess: Function;
  loginFailed: Function;
  requestStart: Function;
  requestEnd: Function;
  fetchBalance: Function;
};

type LoginProps = StoreProps & {
  errorMsg: string;
  loading: boolean;
  loggedIn: boolean;
  modalCloseHandler: Function;
  redirectUrl?: string;
};

const LoginForm: React.FC<LoginProps> = (props) => {
  const {
    errorMsg,
    loading,
    loggedIn,
    loginSuccess,
    loginFailed,
    requestStart,
    requestEnd,
    fetchBalance,
    modalCloseHandler,
    redirectUrl,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [loginResponse, setLoginResponse] = useState(null);
  const [useAuthenticator, setUseAuthenticator] = useState<boolean>(false);
  const [showForgotPwdModal, setShowForgotPwdModal] = useState(false);

  let history = useHistory();

  // const forgotPwdHandler = () => {
  //   setShowForgotPwdModal(true);
  // };

  const formik = useFormik({
    initialValues: { username: '', password: '', code: '' },
    validationSchema: Yup.object({
      username: Yup.string().required('Required'),
      password: Yup.string(),
      code: Yup.string(),
    }),
    onSubmit: async (values) => {
      requestStart();
      try {
        const loginRequest = {
          username: values.username.toLowerCase(),
          password: values.password.trim(),
        };
        const response = await API_V2.post('/account/v2/login', loginRequest);

        setLoginResponse(response.data);
        requestEnd();

        sessionStorage.setItem('username', values.username.toLowerCase());
        sessionStorage.setItem('jwt_token', response.data);
       
        let claim = response.data.split('.')[1];
        let permission = JSON.parse(window.atob(claim)).perm;
        let status = JSON.parse(window.atob(claim)).sts;
       
        sessionStorage.setItem('aid', JSON.parse(window.atob(claim)).aid);
        loginSuccess(response.data);
       
        if (status === 2) {
          history.replace('/terms-and-conditions');
        } else if (status === 4) {
          history.replace('/reset-password');
        } else if ((permission & 2) !== 0) {
          history.replace('/platform_admin/house');
        }

        if (redirectUrl) {
          console.log(redirectUrl);
          history.push(redirectUrl);
        }

      } catch (err) {
        loginFailed(err.response.data.message);
      }
      requestEnd();
    },
  });

  useEffect(() => {
    if (loggedIn) {
      fetchBalance();
    }
  }, [fetchBalance, loggedIn]);

  useEffect(() => {
    if (loggedIn) {
      modalCloseHandler();
    }
  }, [loggedIn, modalCloseHandler]);

  const showPasswordClickHandler = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {loggedIn ? <Redirect to="/admin/dashboard" /> : null}
      <form
        onSubmit={formik.handleSubmit}
        className="login-form-ctn"
        autoComplete="off"
      >
        <div className="title">Admin Sign-in</div>

        <span className="usr-input">
          <IonLabel className="input-label">Email / Username</IonLabel>
          <TextField
            className="login-input-field user-name"
            type="text"
            name="username"
            placeholder="Enter Username"
            variant="outlined"
            error={
              formik.touched.username && formik.errors.username ? true : false
            }
            helperText={
              formik.touched.username && formik.errors.username
                ? formik.errors.username
                : null
            }
            {...formik.getFieldProps('username')}
          />
        </span>

        <div className="pwd-input">
          <IonLabel className="input-label">Password</IonLabel>
          <FormControl
            className="login-input-field pwd-field"
            variant="outlined"
            error={
              formik.touched.password && formik.errors.password ? true : false
            }
          >
            <OutlinedInput
              id="standard-adornment-password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter Password"
              name="password"
              {...formik.getFieldProps('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={showPasswordClickHandler}
                    onMouseDown={showPasswordClickHandler}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {formik.touched.password && formik.errors.password ? (
              <FormHelperText error id="my-helper-text">
                {formik.errors.password}
              </FormHelperText>
            ) : null}
          </FormControl>
        </div>

        {useAuthenticator ? (
          <span className="code-input">
            <IonLabel className="input-label">Code</IonLabel>
            <TextField
              className="login-input-field user-name"
              type="text"
              name="username"
              variant="outlined"
              {...formik.getFieldProps('code')}
            />
          </span>
        ) : null}

        {errorMsg !== '' ? (
          <span className="login-err-msg">{errorMsg}</span>
        ) : null}

        <div
          className="forgot-pwd-link"
          // onClick={() => setShowForgotPwdModal(true)}
        >
          {/* Forgot Password?{' '} */}
        </div>

        <Button
          className="login-form-btn"
          color="primary"
          endIcon={loading ? <IonSpinner name="lines-small" /> : ''}
          type="submit"
          variant="contained"
        >
          Login
        </Button>

        {/* <div className="bottom-link">
        Don't have an account? <span className="register-link">Register</span>
      </div> */}
        {/* <div className="login-form-restrictions-info">
        <AgeRestriction />
        <TerritoriesRestriction />
      </div> */}

        <Modal
          open={showForgotPwdModal}
          closeHandler={() => setShowForgotPwdModal(false)}
          title="Forgot Password"
          size="xs"
        >
          <ForgotPwdForm closeHandler={() => setShowForgotPwdModal(false)} />
        </Modal>
      </form>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.auth.loading,
    loggedIn: state.auth.loggedIn,
    errorMsg: state.auth.loginError,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchBalance: () => dispatch(fetchBalance()),
    loginSuccess: (payload) => dispatch(loginSuccess(payload)),
    loginFailed: (err: string) => dispatch(loginFailed(err)),
    requestStart: () => dispatch(requestStart()),
    requestEnd: () => dispatch(requestEnd()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
